import React from 'react';
import { Else, If, Then } from 'react-if';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { useAuthStore } from '../../providers/RootStoreProvider';

const MobileHeader = dynamic(() =>
  import('./components/MobileHeader').then(mod => mod.MobileHeader),
);

const MobileContent = dynamic(() =>
  import('./components/Header').then(mod => mod.Header),
);

// TODO: Создать UI стор и вынести туда isMobile

export const Layout = observer(
  ({
    children,
    isMobile,
    isTablet,
    isAdvertising,
    shipsSea,
    withoutForm = false,
    isOutsPage,
    isContact,
    isStep,
    isBlog,
    isSea,
    topContent,
    showTopContent,
    emailMetrik,
    popularPopdirs,
  }) => {
    const { isLoggedIn, fullname, id, group, isManager } = useAuthStore();
    const user = { id, group, isManager };
    return (
      <>
        {isLoggedIn && (
          <div className="i-flocktory" data-fl-user-name={fullname} />
        )}
        <If condition={(isMobile || isTablet) && !isStep}>
          <Then>
            <MobileHeader
              isMobile={isMobile}
              shipsSea={shipsSea}
              isOutsPage={isOutsPage}
              isStep={isStep}
              sea={isSea}
              topContent={topContent}
              showTopContent={showTopContent}
              popularPopdirs={popularPopdirs}
            />
          </Then>
          <Else>
            <MobileContent
              isMobile={isMobile}
              shipsSea={shipsSea}
              isOutsPage={isOutsPage}
              isStep={isStep}
              sea={isSea}
              topContent={topContent}
              showTopContent={showTopContent}
              popularPopdirs={popularPopdirs}
            />
          </Else>
        </If>
        <Content isBlog={isBlog} showTopContent={showTopContent}>
          {children}
        </Content>
        <Footer
          isAdvertising={isAdvertising}
          withoutForm={withoutForm}
          isContact={isContact}
          isStep={isStep}
          isMobile={isMobile}
          emailMetrik={emailMetrik}
          user={user}
        />
      </>
    );
  },
);

Layout.defaultProps = {
  isMobile: false,
  isTablet: false,
};
